var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./malli.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var C0,wpa,E0,F0;$CLJS.A0=function(a,b){return $CLJS.cc($CLJS.cb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.bc(c,d):c},$CLJS.ac($CLJS.xf),b))};C0=function(a){return $CLJS.n(B0)?["(",$CLJS.p.h(a),")"].join(""):a};wpa=function(a,b){var c=$CLJS.lk.v(a,$CLJS.$O,$CLJS.QC($CLJS.Yd,$CLJS.xf),b);return $CLJS.vd($CLJS.QD.h(a))?$CLJS.lk.v(c,$CLJS.QD,$CLJS.Yd,$CLJS.pV(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.N,$CLJS.yV(b)],null))):c};
E0=function(a,b,c){var d=$CLJS.yV(c);return $CLJS.R.l($CLJS.Il($CLJS.l_.j(a,b,c),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.tA,$CLJS.pi,$CLJS.MY,$CLJS.wY,$CLJS.D0,$CLJS.Vs],null)),$CLJS.LJ,$CLJS.GY,$CLJS.H([$CLJS.T,d,$CLJS.kD,d]))};F0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.D0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var G0=function(){function a(d,e,f){var k=$CLJS.FV(d,e);k=$CLJS.qG($CLJS.ik.g($CLJS.Tg([f]),$CLJS.yV),$CLJS.$O.h(k));if($CLJS.n(k))return k;throw $CLJS.Uh($CLJS.fD("No expression named {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.$Q,f,$CLJS.EE,d,$CLJS.FK,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.S_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=G0.j(a,b,c);return $CLJS.T_.j(a,b,c)});$CLJS.V_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Vs,$CLJS.JJ,$CLJS.D0,$CLJS.wD.h(d),$CLJS.T,e,$CLJS.oD,e,$CLJS.kD,$CLJS.n_.j(a,b,c),$CLJS.tA,$CLJS.T_.j(a,b,c),$CLJS.LJ,$CLJS.GY],null)});$CLJS.O_.m(null,$CLJS.lA,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.O_.m(null,$CLJS.jA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.O_.m(null,$CLJS.kA,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.O_.m(null,$CLJS.yA,function(a,b,c){return $CLJS.p.h(c)});$CLJS.O_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.P_.m(null,$CLJS.qA,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var B0=!1,xpa=new $CLJS.h(null,4,[$CLJS.Pq,"+",$CLJS.Zr,"-",$CLJS.Qq,"×",$CLJS.CF,"÷"],null),H0=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Pq,$CLJS.Zr,$CLJS.CF,$CLJS.Qq],null)),I0=null,J0=0,K0=0;;)if(K0<J0){var ypa=I0.X(null,K0);$CLJS.oE(ypa,F0);K0+=1}else{var L0=$CLJS.y(H0);if(L0){var M0=L0;if($CLJS.wd(M0)){var N0=$CLJS.ic(M0),zpa=$CLJS.jc(M0),Apa=N0,Bpa=$CLJS.D(N0);H0=zpa;I0=Apa;J0=Bpa}else{var Cpa=$CLJS.z(M0);$CLJS.oE(Cpa,F0);H0=$CLJS.B(M0);I0=null;J0=0}K0=0}else break}
$CLJS.O_.m(null,F0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.z(d);d=$CLJS.B(d);$CLJS.z(d);d=$CLJS.B(d);c=$CLJS.K.g(xpa,c);a:{var e=B0;B0=!0;try{var f=$CLJS.hs([" ",$CLJS.Xg(c)," "].join(""),$CLJS.cf.g($CLJS.Xs($CLJS.n_,a,b),d));break a}finally{B0=e}f=void 0}return C0(f)});$CLJS.P_.m(null,F0,function(){return"expression"});
$CLJS.S_.m(null,$CLJS.xG,function(a,b,c){c=$CLJS.y(c);$CLJS.z(c);c=$CLJS.B(c);$CLJS.z(c);var d=$CLJS.B(c);return $CLJS.Md($CLJS.qE,function(){return function k(f){return new $CLJS.ie(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.wd(l)){var m=$CLJS.ic(l),t=$CLJS.D(m),u=$CLJS.le(t);a:for(var v=0;;)if(v<t){var x=$CLJS.id(m,v);x=$CLJS.T_.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.oe($CLJS.qe(u),k($CLJS.jc(l))):$CLJS.oe($CLJS.qe(u),null)}u=$CLJS.z(l);return $CLJS.ae($CLJS.T_.j(a,
b,u),k($CLJS.Hc(l)))}return null}},null,null)}(d)}())});$CLJS.O_.m(null,$CLJS.aB,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.n_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.u0.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=$CLJS.yz(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=C0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.P_.m(null,$CLJS.aB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.Q_.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.u0.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=$CLJS.yz(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.O_.m(null,$CLJS.lF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.n_.v(a,b,e,d)});$CLJS.P_.m(null,$CLJS.lF,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.Q_.j(a,b,d)});
$CLJS.Dpa=function(){function a(d,e,f,k){return $CLJS.QV.l(d,$CLJS.n(e)?e:-1,wpa,$CLJS.H([$CLJS.zV($CLJS.rV.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Epa=function(){function a(d,e){var f=$CLJS.Be($CLJS.$O.h($CLJS.FV(d,e)));return null==f?null:$CLJS.kk.g($CLJS.Xs(E0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.O0=function(){function a(d,e){return $CLJS.Be($CLJS.$O.h($CLJS.FV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.oV.m(null,$CLJS.qA,function(a){return a});
$CLJS.Fpa=function(){function a(d,e,f){var k=$CLJS.Wf.g($CLJS.N,$CLJS.Hl(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yV(t),m],null)},$CLJS.O0.g(d,e))),l=$CLJS.FV(d,e);d=$CLJS.$_.j(d,e,l);return $CLJS.Be($CLJS.A0(function(m){return $CLJS.Ra(f)||$CLJS.hk.g($CLJS.LJ.h(m),$CLJS.GY)||$CLJS.K.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Gpa=function(){function a(d,e,f){return $CLJS.qV(E0(d,e,G0.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.P0=$CLJS.lE.h($CLJS.WD);