var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var MI;$CLJS.KI=function(a){return $CLJS.n($CLJS.pB($CLJS.sd,$CLJS.rd,$CLJS.dl)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.LI=function(a,b){return $CLJS.Me($CLJS.Ad,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(J,S,X,W){return function(){for(var da=C;;){var ra=$CLJS.y(da);if(ra){var Ma=ra,sb=$CLJS.z(Ma);if(ra=$CLJS.y(function(Qa,Xa,Ja,Va,Ta,mb,ec,ed){return function Rh(hd){return new $CLJS.ie(null,function(sz,Jl,Wn,Ar,U_,Nv){return function(){for(;;){var Ov=
$CLJS.y(hd);if(Ov){if($CLJS.wd(Ov)){var Pv=$CLJS.ic(Ov),tz=$CLJS.D(Pv),Fk=$CLJS.le(tz);a:for(var Br=0;;)if(Br<tz){var gt=$CLJS.id(Pv,Br);gt=$CLJS.E.g(Nv,$CLJS.XD)||$CLJS.E.g(Wn,$CLJS.XD)||$CLJS.yE(Nv,gt)&&$CLJS.yE(Wn,gt);Fk.add(gt);Br+=1}else{Pv=!0;break a}return Pv?$CLJS.oe($CLJS.qe(Fk),Rh($CLJS.jc(Ov))):$CLJS.oe($CLJS.qe(Fk),null)}Fk=$CLJS.z(Ov);return $CLJS.ae($CLJS.E.g(Nv,$CLJS.XD)||$CLJS.E.g(Wn,$CLJS.XD)||$CLJS.yE(Nv,Fk)&&$CLJS.yE(Wn,Fk),Rh($CLJS.Hc(Ov)))}return null}}}(Qa,Xa,Ja,Va,Ta,mb,ec,
ed),null,null)}}(da,J,sb,Ma,ra,S,X,W)($CLJS.iE)))return $CLJS.bf.g(ra,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.KI($CLJS.KD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.KI($CLJS.KD(a)))}())};
MI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nr,$CLJS.Ne.j($CLJS.uE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ci,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.MD;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.ol,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.LI($CLJS.K.g(k,m),$CLJS.K.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.NI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.OI=new $CLJS.M("operator","filter","operator/filter",-1518842858);
$CLJS.PI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var QI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nr,$CLJS.ir],null)),RI=null,SI=0,TI=0;;)if(TI<SI){var Kia=RI.X(null,TI);$CLJS.xE(Kia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],null)],null)]));TI+=1}else{var UI=$CLJS.y(QI);if(UI){var VI=UI;if($CLJS.wd(VI)){var WI=$CLJS.ic(VI),Lia=$CLJS.jc(VI),
Mia=WI,Nia=$CLJS.D(WI);QI=Lia;RI=Mia;SI=Nia}else{var Oia=$CLJS.z(VI);$CLJS.xE(Oia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)],null)],null)],null)]));QI=$CLJS.B(VI);RI=null;SI=0}TI=0}else break}$CLJS.vE($CLJS.kr,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VD],null)]));
for(var XI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,$CLJS.yF],null)),YI=null,ZI=0,$I=0;;)if($I<ZI){var Pia=YI.X(null,$I);$CLJS.xE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)],null)],null)],null)]));$I+=1}else{var aJ=$CLJS.y(XI);if(aJ){var bJ=aJ;if($CLJS.wd(bJ)){var cJ=$CLJS.ic(bJ),Qia=$CLJS.jc(bJ),
Ria=cJ,Sia=$CLJS.D(cJ);XI=Qia;YI=Ria;ZI=Sia}else{var Tia=$CLJS.z(bJ);$CLJS.xE(Tia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xq,new $CLJS.h(null,1,[$CLJS.ui,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)],null)],null)],null)]));XI=$CLJS.B(bJ);YI=null;ZI=0}$I=0}else break}
for(var dJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Kq,$CLJS.Mq,$CLJS.Gq,$CLJS.Iq],null)),eJ=null,fJ=0,gJ=0;;)if(gJ<fJ){var Uia=eJ.X(null,gJ);$CLJS.tE.l(MI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)]));gJ+=1}else{var hJ=$CLJS.y(dJ);if(hJ){var iJ=hJ;if($CLJS.wd(iJ)){var jJ=$CLJS.ic(iJ),Via=$CLJS.jc(iJ),Wia=jJ,Xia=$CLJS.D(jJ);dJ=Via;eJ=Wia;fJ=Xia}else{var Yia=
$CLJS.z(iJ);$CLJS.tE.l(MI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)]));dJ=$CLJS.B(iJ);eJ=null;fJ=0}gJ=0}else break}
$CLJS.tE.l(MI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.KE,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)]));
$CLJS.tE.l(MI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.QE,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.bE],null)]));
for(var kJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JE,$CLJS.VE],null)),lJ=null,mJ=0,nJ=0;;)if(nJ<mJ){var Zia=lJ.X(null,nJ);$CLJS.vE(Zia,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));nJ+=1}else{var oJ=$CLJS.y(kJ);if(oJ){var pJ=oJ;if($CLJS.wd(pJ)){var qJ=$CLJS.ic(pJ),$ia=$CLJS.jc(pJ),aja=qJ,bja=$CLJS.D(qJ);kJ=$ia;lJ=aja;mJ=bja}else{var cja=$CLJS.z(pJ);$CLJS.vE(cja,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));kJ=$CLJS.B(pJ);
lJ=null;mJ=0}nJ=0}else break}
for(var rJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LE,$CLJS.SE],null)),sJ=null,tJ=0,uJ=0;;)if(uJ<tJ){var dja=sJ.X(null,uJ);$CLJS.vE(dja,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));uJ+=1}else{var vJ=$CLJS.y(rJ);if(vJ){var wJ=vJ;if($CLJS.wd(wJ)){var xJ=$CLJS.ic(wJ),eja=$CLJS.jc(wJ),fja=xJ,gja=$CLJS.D(xJ);rJ=eja;sJ=fja;tJ=gja}else{var hja=$CLJS.z(wJ);$CLJS.vE(hja,$CLJS.H([$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.WD],null)]));rJ=$CLJS.B(wJ);
sJ=null;tJ=0}uJ=0}else break}
for(var yJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null),zJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$E,$CLJS.tF,$CLJS.hF,$CLJS.aF],null)),AJ=null,BJ=0,CJ=0;;)if(CJ<BJ){var DJ=AJ.X(null,CJ);$CLJS.HF.v(DJ,$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,DJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,yJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,
$CLJS.eE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eE],null)],null));CJ+=1}else{var EJ=$CLJS.y(zJ);if(EJ){var FJ=EJ;if($CLJS.wd(FJ)){var GJ=$CLJS.ic(FJ),ija=$CLJS.jc(FJ),jja=GJ,kja=$CLJS.D(GJ);zJ=ija;AJ=jja;BJ=kja}else{var HJ=$CLJS.z(FJ);$CLJS.HF.v(HJ,$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,HJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,yJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.V,$CLJS.eE],null)],null));zJ=$CLJS.B(FJ);AJ=null;BJ=0}CJ=0}else break}
$CLJS.HF.v($CLJS.cB,$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,$CLJS.cB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.uD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Nk,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.tr],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.TD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.fr,$CLJS.Qu,$CLJS.Vw,$CLJS.PE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.hE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZF],null)],null));$CLJS.HF.v($CLJS.GE,$CLJS.Zr,$CLJS.pj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,$CLJS.GE],null),$CLJS.uD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ir,$CLJS.hD,$CLJS.pD],null)],null));
$CLJS.Y($CLJS.NI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Nq,$CLJS.OI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.fr,$CLJS.Nq,$CLJS.yF,$CLJS.QE,$CLJS.KE,$CLJS.Kq,$CLJS.Gq,$CLJS.Mq,$CLJS.Iq,$CLJS.JE,$CLJS.VE,$CLJS.LE,$CLJS.SE,$CLJS.hF,$CLJS.aF,$CLJS.$E,$CLJS.tF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PI,$CLJS.Ii],null)],null));